<template>
    <Overlay v-if="isLoading" />
    <h5 v-if="!hideHeader">{{ $t('Export') }}</h5>

    <div id="exportConfigContainer" class="border bg-body-tertiary p-1 mb-2 min-h-unset">
        <div class="mb-3 mx-2">
            <label for="sheetNameInput" class="form-label">{{ $t('Sheet name') }}</label>
            <div class="input-group ">
                <input type="text" class="form-control form-control-sm rounded-0" id="sheetNameInput"
                    :placeholder="$t('Data Export')" v-model="exportConfig.sheetName">
                <button class="btn btn-sm btn-outline-primary dropdown-toggle" type="button" 
                    :disabled="columnsSelected" data-bs-toggle="dropdown" aria-expanded="false">{{$t('Export')}}</button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li>
                        <button class="dropdown-item" @click="exportData('xlsx')" :disabled="columnsSelected">
                            <i class="bi bi-filetype-xlsx pe-1"></i>
                            {{ $t('Export to excel') }}
                        </button>
                    </li>
                    <li>
                        <button class="dropdown-item" @click="exportData('pdf')" :disabled="columnsSelected">
                            <i class="bi bi-filetype-pdf pe-1"></i>
                            {{ $t('Export to pdf') }}
                        </button>
                    </li>
                    <li>
                        <button class="dropdown-item" @click="exportData('csv')" :disabled="columnsSelected">
                            <i class="bi bi-filetype-csv pe-1"></i>
                            {{ $t('Export to csv') }}
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div class="mx-2">
            <button 
                class="btn btn-sm btn-outline-primary  mb-3" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#collapseDetails" 
                aria-expanded="false" 
                aria-controls="collapseDetails"
            >
                {{ $t('More settings')}}
            </button>

            <div class=" collapse " id="collapseDetails">
                <div class="form-check d-inline-block me-3">
                    <input class="form-check-input" type="checkbox" value="" id="includeTitleRowCheck"
                        v-model="exportConfig.includeTitleRow">
                    <label class="form-check-label" for="includeTitleRowCheck">
                        {{ $t('Title Row') }}
                    </label>
                </div>

                <div class="form-check d-inline-block me-3">
                    <input class="form-check-input" type="checkbox" value="" id="includeHeaderCheck"
                        v-model="exportConfig.includeHeader">
                    <label class="form-check-label" for="includeHeaderCheck">
                        {{ $t('Header Row') }}
                    </label>
                </div>

                <!--<div class="form-check d-inline-block me-3">
                    <input class="form-check-input" type="checkbox" value="" id="includeDataTypesCheck"
                        v-model="exportConfig.includeDataTypes">
                    <label class="form-check-label" for="includeDataTypesCheck">
                        {{ $t('Data Types Row') }}
                    </label>
                </div> -->

            <!-- <div class="form-check d-inline-block me-3">
                    <input class="form-check-input" type="checkbox" value="" id="localizeDateTime"
                        v-model="exportConfig.localizeDateTime">
                    <label class="form-check-label" for="localizeDateTime">
                        {{ $t('Local Datetime') }}
                    </label>
                </div>-->

                <div class="form-check d-inline-block me-3">
                    <input class="form-check-input" type="checkbox" value="" id="includeFilterCheck"
                        v-model="exportConfig.includeFilter">
                    <label class="form-check-label" for="includeFilterCheck">
                        {{ $t('Filter Row') }}
                    </label>
                </div>

                <div class="form-check d-inline-block me-3">
                    <input class="form-check-input" type="checkbox" value="" id="includePivotTableCheck"
                        v-model="exportConfig.includePivotTable">
                    <label class="form-check-label" for="includePivotTableCheck">
                        {{ $t('Pivot Sheet') }}
                    </label>
                </div>

                <div class="form-check d-inline-block me-3">
                    <input class="form-check-input" type="checkbox" value="" id="autoFitColumns"
                        v-model="exportConfig.autoFitColumns">
                    <label class="form-check-label" for="autoFitColumns">
                        {{ $t('Auto-fit Columns') }}
                    </label>
                </div>

                <div class="form-check d-inline-block me-3">
                    <input class="form-check-input" type="checkbox" value="" id="includeExportedDateTime"
                        v-model="exportConfig.includeExportedDateTime">
                    <label class="form-check-label" for="includeExportedDateTime">
                        {{ $t('Exported Date') }}
                    </label>
                </div>
                <div class="d-flex gap-1 " v-if="!dataObject.export.disableUserSetMaxRecords">
                    <input type="number" min="1" style="width: 8%;" placeholder="-1"   v-model="dataObject.export.maxRecords" id="maxRecords" class="form-control form-control-sm" aria-describedby="pagesNumberHelp">
                    <label for="maxRecords" class="col-form-label">{{$t('Max records')}}</label>
                </div>
            </div>
            
        </div>
    </div>

<!--
    <div class="pb-2 min-h-unset">

        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-10 ps-0">
                    <button class="btn  btn-outline-primary btn-sm float-start me-2 mb-1" @click="exportData('xlsx')" :disabled="columnsSelected">
                        <i class="bi bi-filetype-xlsx pe-1"></i>
                        {{ $t('Export to excel') }}
                    </button>
                    <button class="btn  btn-outline-primary btn-sm float-start me-2 mb-1" @click="exportData('pdf')" :disabled="columnsSelected">
                        <i class="bi bi-filetype-pdf pe-1"></i>
                        {{ $t('Export to pdf') }}
                    </button>


         
                    <template v-if="columnsChooserRef?.control.allColumnsSelected?.value">
                        <button class="btn btn-outline-primary btn-sm float-start"
                            @click="columnsChooserRef.control.chooseAllColumns(false)">
                            <i class="bi bi-check-all"></i>
                            {{ $t('Unselect all columns') }}
                        </button>
                    </template>
                    <template v-else-if="columnsChooserRef?.control.allColumnsSelected?.value === false">
                        <button class="btn  btn-outline-primary btn-sm float-start"
                            @click="columnsChooserRef.control.chooseAllColumns(true)">
                            <i class="bi bi-check-all"></i>
                            {{ $t('Select all columns') }}
                        </button>
                    </template>
           

                </div>
       
                <div class="col-2 pe-0">
                    <button @click="toggleSettings" type="button" class="btn float-end"
                        :class="{ 'btn-edit': !isSettingsVisible, 'btn-link, bg-body-tertiary': isSettingsVisible }"
                        data-bs-toggle="tooltip" data-bs-target="#exportConfigContainer" aria-expanded="false"
                        aria-controls="exportConfigContainer" :data-bs-title="$t('Export settings')">
                        <i class="bi bi-gear"></i>
                    </button>
                </div>
    
            </div>
        </div>


    </div>
    -->
    <OColumnChooser ref="columnsChooserRef" :dataColumns="gridRef.dataColumns" :columns="columns" :disableSearch="disableSearch" :watchTarget="watchTarget">
        <template #beforeList>
            <ul class="nav nav-tabs my-1 me-3 mb-1" v-if="dataObject.export.previousColumns">
                <li class="nav-item " >
                    <button class="nav-link" :class="{ 'active': activeTab === 'currentLayout' }" @click="setActiveTab('currentLayout')" role="tab">
                        {{ $t("Current Column Layout") }}
                    </button>
                </li>
                <li class="nav-item ">
                    <button class="nav-link" :class="{ 'active': activeTab === 'previousExport' }" @click="setActiveTab('previousExport')" role="tab">
                        {{ $t("Previous Export Layout") }}
                    </button>
                </li>
            </ul>
        </template>
    </OColumnChooser>
    <ImportData v-if="gridRef.importData" :gridRef="gridRef" :columns="columns" class="py-2 border-top" hideHeader  />

</template>

<script setup>
import OColumnChooser from 'o365.vue.components.ColumnChooser.vue';
import { defineProps, onMounted, ref, computed, reactive, watch } from "vue";
import { ExportConfig } from 'o365.modules.exportData.ts';
import { Overlay } from 'o365.vue.components.jsx'
import DataColumn from 'o365.controls.DataGrid.Column.ts';
import alert from 'o365.controls.alert.ts';
import 'o365.modules.DataObject.extensions.ExportData.ts';
import ImportData from 'o365.vue.components.ImportData.vue';

const props = defineProps({
    dataObject: null,
    gridRef: null,
    hideHeader: Boolean,
    disableSearch: Boolean,
    watchTarget: null
});

const columnsChooserRef = ref(null);

const activeTab = ref('currentLayout')

const columns = reactive([]),
    columnGroups = ref(null),
    isLoading = ref(false),
    isSettingsVisible = ref(false),
    exportConfig = reactive(new ExportConfig());
let dataObject = props.dataObject ? props.dataObject : props.gridRef.dataObject;


// Populate export config
exportConfig.sheetName = dataObject?.export.getDefaultSheetName() ?? dataObject?.viewName?.split('_')?.pop() ?? 'Data Export';

function setActiveTab(pTab) {
    activeTab.value = pTab;
    if (pTab === 'previousExport') {
        columns.splice(0, columns.length, ...dataObject.export.previousColumns);
    } else {
        setColumns();
    }
    columnsChooserRef.value?.control?.forceUpdate();
}

function setColumns() {
    columnGroups.value = null;
    columns.splice(0, columns.length)
    if (props.gridRef) {
        props.gridRef.dataColumns.columns.forEach(col => {
            let fieldExists = col.name.startsWith('Property.')
                ? dataObject.fields[col.name] != null
                : dataObject.metadata?.isFromDesigner
                    ? dataObject.fields.fieldExistsInView(col.name)
                    : dataObject.fields[col.name];
            if (!col.name.startsWith("o365") && fieldExists) {
                columns.push(new DataColumn({
                    name: col.name,
                    field: col.field,
                    colId: col.colId,
                    headerName: col.headerName,
                    caption: col.caption ?? col.name,
                    width: Math.round(col._width),
                    type: col.type,
                    format: col.format,
                    shown: col.shown,
                    hide: col.hide,
                    class: col.cellClass,
                    link: col.exportMetaData?.linkUrl != null ? col.exportMetaData.linkUrl : null,
                    summaryAggregate: col.summaryAggregate,
                    parentGroupId: col.parentGroupId,
                    required: col.required
                }));
            }
        });

        if (props.gridRef.dataColumns.columnGroups?.length > 0) {
            columnGroups.value = props.gridRef.dataColumns.columnGroups.map(g => g.map(r => {
                return {
                    id: r.groupId,
                    name: r.headerName,
                    parentId: r.parentGroupId
                };
            }));

            columnGroups.value[0] = columnGroups.value[0].filter(fg => 
                !columnGroups.value.find((cg, idx) => idx != 0 
                && !!cg.find(g => g.id == fg.id)));
        }
    }
}
setColumns();

if (props.watchTarget) {
    watch(() => props.watchTarget, () => { 
        setColumns();
     });
}

const columnsSelected = computed(() => {
    return !(columns.filter(x => x.shown === true).length > 0)
})


onMounted(() => {
    // Enable bs tooltips
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl, {
        trigger: 'hover'
    }));
});

function getAlignment(columnClass) {
    if (columnClass?.includes("text-end") ){
        return "right";
    }

    if (columnClass?.includes("text-center") ){
        return "center";
    }

    return undefined;
}

const populateExportConfig = (columns) => {
    const arr = [];
    for (const col of columns.filter(x => x.shown === true)) {
        if (props.gridRef) {
            // Update export meta data
            const dataColumn = props.gridRef.dataColumns.getColumn(col.colId);
            if (dataColumn && dataColumn.exportMetaData) {
                col.exportMetaData = JSON.parse(JSON.stringify(dataColumn.exportMetaData));
            }
        }
        arr.push({
            name: col.name,
            field: col.field,
            colId: col.colId,
            headerName: col.headerName,
            caption: col.caption ?? col.name,
            width: Math.round(col._width),
            dataType: col.type,
            format: col.format,
            shown: col.shown,
            hide: col.hide,
            align: getAlignment(col.cellClass),
            link: col.exportMetaData?.linkUrl != null ? col.exportMetaData.linkUrl : null,
            summaryAggregate: col.summaryAggregate,
            parentGroupId: col.parentGroupId,
            required: col.required
        });
    }
    return arr;
}

const toggleSettings = () => {
    const bsCollapse = new window.bootstrap.Collapse('#exportConfigContainer');

    isSettingsVisible.value = !isSettingsVisible.value;
}

const exportData = async (pFormat) => {
    isLoading.value = true;
    if (dataObject == null) { return; }
    dataObject.export.isLoading = true;

    exportConfig.fileName = dataObject?.export.getFileName();

    exportConfig.columns = populateExportConfig(columns)
    if(columnGroups.value){
        exportConfig.columnGroups = [getUsedColumnGroups(columnGroups.value[0],exportConfig.columns)]
         //exportConfig.columnGroups = columnGroups.value;
    }
    

    if (props.gridRef) {
        props.gridRef.dataObject.emit('BeforeExport', exportConfig);
    }
const maxRecordsSend  = { maxRecords: dataObject.export.maxRecords === null||dataObject.export.maxRecords ===undefined  ? -1 : dataObject.export.maxRecords};
    await dataObject.export.exportData(exportConfig, maxRecordsSend, pFormat).catch((error) => {
        alert(error);
        isLoading.value = false;
        dataObject.export.isLoading = false;
    });
    dataObject.export.previousColumns = [...columns];
  
    isLoading.value = false;
    dataObject.export.isLoading = false;
    setActiveTab('previousExport');
}

function getUsedColumnGroups(pGroups,pColumns){

    let lastGroupId = null;
    const distinctGroups = [];
   /* pColumns.forEach(col=>{
        
        if(col.parentGroupId && !distinctGroups.find(x=>x.id == col.parentGroupId)){
            distinctGroups.push(pGroups.find(x=>x.id==col.parentGroupId));
        }
    });*/
     pColumns.forEach(col=>{
        if(col.parentGroupId && col.parentGroupId != lastGroupId){
            lastGroupId = col.parentGroupId;
            const vGroup = pGroups.find(x=>x.id == col.parentGroupId);
        
            const vFoundLength = distinctGroups.filter(x=>x.id == col.parentGroupId).length;
            col.parentGroupId = col.parentGroupId+(vFoundLength?vFoundLength:'');
            if(vGroup) distinctGroups.push({
                id:col.parentGroupId,
                
                name:vGroup.name,
                parentId: vGroup.parentId

            })
        }
    });

     

    return distinctGroups;
}

function setSearchValue(pValue) {
    if (columnsChooserRef.value?.setSearchValue) {
        columnsChooserRef.value.setSearchValue(pValue);
    }
}

const chooseAllColumns = (value = true) => {
    return columnsChooserRef.value.control.chooseAllColumns(value);
}

const allColumnsSelected = computed(() => {
    return columnsChooserRef.value.control.allColumnsSelected.value;
})

const someColumnsSelected = computed(() => {
    return columnsChooserRef.value.control.someColumnsSelected.value;
})

const control = {
    chooseAllColumns,
    allColumnsSelected,
    someColumnsSelected
}

defineExpose({ setSearchValue, control });

</script>

<style scoped>
.export-buttons {
    flex: 0 0 40px;
}
.nav-link.active {
    border-color: var(--bs-nav-tabs-link-active-border-color)!important;
}
</style>